import React, { useContext, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material/";
import "../../../util/theme/slick.css";
import "../../../util/theme/slick-theme.css";
import Slider from "react-slick";
import { getMerchantContent } from "../../Map/mapmethods";
import { MapContext } from "../../../contexts";
import CATEGORIES from "../../../util/categories";

const PREFIX = "TopAttractionsCarousel";

const classes = {
  attractions: `${PREFIX}-attractions`,
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  placeholder: `${PREFIX}-placeholder`,
};

const StyledSlider = styled(Slider)(({ theme }) => ({
  [`&.${classes.attractions}`]: {
    "& a:hover": {
      color: theme.palette.common.white,
    },
    "& .slick-disabled": {
      display: "none !important",
    },
    "& .slick-next, & .slick-prev": {
      height: theme.spacing(10),
      width: theme.spacing(10),
      borderRadius: theme.spacing(10),
      background: theme.palette.common.white,
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.3)",
      transition: "all 0.4s",
      zIndex: 1,

      "&:before": {
        content: "''",
        height: 15,
        width: 15,
        border: `3px solid ${theme.palette.secondary.main}`,
        display: "block",
        transform: "rotate(45deg)",
        borderLeft: 0,
        borderBottom: 0,
        marginLeft: 9,
      },
      "&:hover, &:focus": {
        "&:before": {
          borderColor: theme.palette.primary,
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    "& .slick-prev:before": {
      transform: "rotate(225deg) !important",
      marginLeft: "14px !important",
    },
    "& .slick-next": {
      right: "0",
    },
    "& .slick-prev": {
      left: "-21px",
    },
    "& .slick-track": {
      display: "flex !important",
      marginLeft: 0,
      marginRight: 0,
    },
    "& .slick-slide": {
      height: "auto",
      borderRadius: theme.spacing(3),
      border: `1px solid ${theme.palette.borders.light}`,
      marginRight: theme.spacing(5),
      overflow: "hidden",
    },
    "& .slick-list": {
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(-2.5),
      },
    },
  },

  [`& .${classes.card}`]: {
    color: theme.palette.secondary.main,
    position: "relative",
    "&:hover h4": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.media}`]: {
    height: theme.spacing(25),
    backgroundColor: theme.palette.background.light,
    overflow: "hidden",
    position: "relative",
    "& img": {
      position: "absolute",
      left: "-10000%",
      right: "-10000%",
      top: "-10000%",
      bottom: "-10000%",
      margin: "auto auto",
      minWidth: "1000%",
      minHeight: "1000%",
      "-webkit-transform": "scale(0.1)",
      transform: "scale(0.1)",
    },
  },

  [`& .${classes.placeholder}`]: {
    color: theme.palette.common.white,
    backdropFilter: "blur(4px)",
    background: "rgba(255, 255, 255, 0.5)",
    height: theme.spacing(25),
    width: "100%",
    position: "absolute",
    top: 0,
    "& svg": {
      transform: "scale(3)",
      position: "absolute",
      top: theme.spacing(13),
    },
  },
}));

const TopAttractionsCarousel = ({ attractions, map }) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const slidesToShow = fullScreen ? 4 : mdScreen ? 3.5 : 1.5;
  const { cityView, googleData, setGoogleData, setDrawer } =
    useContext(MapContext);
  const sliderRef = useRef();
  async function getTopAttractions(a) {
    const data = a.map(async (t) => {
      if (googleData && googleData[t.google_place_id]) {
        return googleData[t.google_place_id];
      } else {
        const value = await getMerchantContent(
          t.google_place_id,
          map,
          theme.lang
        );
        return value;
      }
    });
    const values = await Promise.all(data);

    let currentGoogleData = { ...googleData };

    values
      .filter((v) => v !== undefined)
      .forEach((v) => {
        const existing = attractions.find(
          (a) => a.google_place_id === v.place_id
        );

        let address = v.formatted_address;
        if (
          ["New York", "Los Angeles", "Miami", "Honolulu"].includes(cityView)
        ) {
          if (v.address_components) {
            const streetnum = v.address_components.filter((a) =>
              a.types.includes("street_number")
            )[0];
            const street = v.address_components.filter((a) =>
              a.types.includes("route")
            )[0];
            address = `${streetnum ? streetnum.long_name : ""} ${
              street ? street.long_name : ""
            }`;
          }
        }

        if (address === " ") {
          address = v.formatted_address;
        }

        if (v.address_components) {
          delete v.address_components;
        }
        const objData = {
          [v.place_id]: {
            name: existing?.name,
            address: address,
            mcc: existing?.mcc,
            ...v,
          },
        };
        currentGoogleData = { ...currentGoogleData, ...objData };
      });
    setGoogleData(
      Object.keys(googleData).length
        ? { ...googleData, ...currentGoogleData }
        : currentGoogleData
    );
  }

  useEffect(() => {
    //get the first 4 once
    getTopAttractions([...attractions].slice(0, 4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //if there are less slides than slides to show for some reason the carousel aligns them to the right side with a css transform. so here is a hack to fix that. maybe in the future this issue will be fixed.
  useEffect(() => {
    if (sliderRef.current) {
      setTimeout(() => {
        if (sliderRef.current?.innerSlider) {
          sliderRef.current.innerSlider.track.node.style.transform =
            "translate3d(0px, 0px, 0px)";
        }
      }, 100);
    }
  });

  return (
    <StyledSlider
      dots={false}
      infinite={false}
      speed={500}
      ref={sliderRef}
      slidesToShow={slidesToShow}
      slidesToScroll={fullScreen ? 4 : mdScreen ? 3 : 1}
      className={classes.attractions}
      beforeChange={(current, next) => {
        getTopAttractions(
          [...attractions].slice(next, next + slidesToShow + 1)
        );
      }}
    >
      {attractions &&
        attractions.map((c) => {
          return (
            <div key={c.name}>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setDrawer(c);
                }}
              >
                <Card className={classes.card} elevation={0}>
                  {googleData[c.google_place_id] &&
                  googleData[c.google_place_id].photos ? (
                    <CardMedia className={classes.media}>
                      <img
                        src={googleData[c.google_place_id].photos[0].getUrl()}
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = `/PlaceholderImages/${CATEGORIES.find(
                            (d) => d.mcc === c.mcc
                          )?.title.toLowerCase()}/photo${Math.floor(
                            Math.random() * 3 + 1
                          )}.jpg`;
                        }}
                      />
                    </CardMedia>
                  ) : (
                    <>
                      <CardMedia
                        className={classes.media}
                        image={`/PlaceholderImages/${CATEGORIES.find(
                          (d) => d.mcc === c.mcc
                        )?.title.toLowerCase()}/photo${Math.floor(
                          Math.random() * 3 + 1
                        )}.jpg`}
                      />
                      <Box
                        className={classes.placeholder}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {CATEGORIES.find((d) => d.mcc === c.mcc)?.icon}
                      </Box>
                    </>
                  )}
                  <CardContent
                    style={{
                      padding: theme.spacing(5),
                      paddingBottom: theme.spacing(2.5),
                    }}
                  >
                    <Typography>
                      <b>
                        {theme.lang === "en"
                          ? c.name
                          : googleData[c.google_place_id]?.name ?? c.name}
                      </b>
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </div>
          );
        })}
    </StyledSlider>
  );
};

TopAttractionsCarousel.propTypes = {
  attractions: PropTypes.array.isRequired,
  map: PropTypes.object.isRequired,
};

export default TopAttractionsCarousel;
